import { MenuUnfoldOutlined } from "@ant-design/icons";
import { ShopOutlined } from "@ant-design/icons";
import { HomeOutlined, LoginOutlined, LogoutOutlined, UserOutlined, CalendarOutlined } from "@ant-design/icons";
import React from "react";
import { ROLES } from "./permissions";

export const routes = {
	home: {
		key: "home",
		slug: "home",
		to: "/home",
		title: "Dashboard",
		icon: <HomeOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	profile: {
		key: "profile",
		slug: "profile",
		to: "/profile",
		title: "Perfil",
		icon: <UserOutlined />,
		showInMenu: false,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	customers: {
		key: "customers",
		slug: "customers",
		to: "/customers",
		title: "Clientes",
		icon: <ShopOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	visits: {
		key: "visits",
		slug: "visits",
		to: "/visits",
		title: "Visitas",
		icon: <MenuUnfoldOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
	},

	visitscalendar: {
		key: "visitscalendar",
		slug: "visitscalendar",
		to: "/visitscalendar",
		title: "Calendario",
		icon: <CalendarOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: false,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	users: {
		key: "users",
		slug: "users",
		to: "/users",
		title: "Usuarios",
		icon: <UserOutlined />,
		showInMenu: true,
		showHeader: true,
		showSearch: true,
		action: null,
		role: [ROLES.admin.role],
	},

	login: {
		key: "login",
		slug: "login",
		to: "/login",
		title: "LOGIN",
		icon: <LoginOutlined />,
		showInMenu: false,
		showHeader: false,
		showSearch: false,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},

	logout: {
		key: "logout",
		slug: "logout",
		to: "/logout",
		title: "Log out",
		icon: <LogoutOutlined />,
		showInMenu: false,
		showHeader: false,
		showSearch: false,
		action: null,
		role: [ROLES.admin.role, ROLES.user.role],
	},
};
