/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* VISIT */
export const SET_VISIT_LIST = "SET_VISIT_LIST";
export const ADD_NEW_VISIT = "ADD_NEW_VISIT";
export const UPDATE_VISIT = "UPDATE_VISIT";
export const DELETE_VISIT = "DELETE_VISIT";
export const OPEN_VISIT_FORM = "OPEN_VISIT_FORM";
export const CLOSE_VISIT_FORM = "CLOSE_VISIT_FORM";
export const EDIT_SELECTED_VISIT = "EDIT_SELECTED_VISIT";
export const VISIT_FORM_TOOGLE_LOADING = "VISIT_FORM_TOOGLE_LOADING";
/* <---- VISIT ----> */

/* CUSTOMER */
export const SET_CUSTOMER_LIST = "SET_CUSTOMER_LIST";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const OPEN_CUSTOMER_FORM = "OPEN_CUSTOMER_FORM";
export const CLOSE_CUSTOMER_FORM = "CLOSE_CUSTOMER_FORM";
export const EDIT_SELECTED_CUSTOMER = "EDIT_SELECTED_CUSTOMER";
export const CUSTOMER_FORM_TOOGLE_LOADING = "CUSTOMER_FORM_TOOGLE_LOADING";
/* <---- CUSTOMER ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
